import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--font-inter\"}],\"variableName\":\"interSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Satisfy\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"variable\":\"--font-satisfy\"}],\"variableName\":\"satisfySans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Ms_Madi\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"variable\":\"--font-satisfy\"}],\"variableName\":\"msMadi\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Kalam\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"variable\":\"--font-satisfy\"}],\"variableName\":\"kalam\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.tsx\",\"import\":\"Vujahday_Script\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"variable\":\"--font-satisfy\"}],\"variableName\":\"vujahday\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/app/_components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProviderClient"] */ "/vercel/path0/src/locales/client.ts");
